/* Existing styles remain the same */
.container .three-columns {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap; /* Allow the columns to wrap onto the next line if needed */
}

.container .three-columns .box {
  flex: 1;
  padding: 10px;
  text-align: center;
  background-color: #f4f4f4;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 30px;
}

.container .three-columns .box img {
  height: 200px;
  width: auto;
  object-fit: cover;
  margin-bottom: 10px;
}

.container .three-columns .box p {
  margin-top: 10px;
  color: #555;
}

/* Bold text color */
b, strong, h1 {
  color: #FF0077;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .container .three-columns {
    flex-direction: column; /* Stack columns vertically */
  }

  .container .three-columns .box {
    margin-bottom: 20px;
  }

  .container .three-columns .box img {
    height: auto; /* Allow image to resize naturally */
    width: 100%;  /* Ensure image takes full width of the box */
  }
}